:root {
  --plyr-color-main: #ef4444;
  --plyr-control-icon-size: 20px;
  --plyr-badge-background: #ef4444;
  --plyr-badge-border-radius: 4px;
  --plyr-control-radius: 4px;
  --plyr-line-height: 2.2rem;
  --plyr-font-size-badge: 10px;
  --plyr-captions-background: #0000;
}

.plyr__caption {
  line-height: 100%;
}

.plyr__caption > span {
  font-family: "Roboto";
  font-size: 2.4rem;
  font-weight: bold;
  font-stretch: extra-expanded;
  text-shadow: 1px 1px 3px #000, 1px -1px 3px #000, -1px -1px 3px #000,
    -1px 1px 3px #000;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 1200px) {
  .plyr__caption > span {
    font-family: "Roboto";
    font-size: 1.8rem;
    font-weight: bold;
    font-stretch: extra-expanded;
    text-shadow: 1px 1px 3px #000, 1px -1px 3px #000, -1px 1px 3px #000,
      -1px 1px 3px #000;
    -webkit-font-smoothing: antialiased;
  }
}

@media screen and (max-width: 900px) {
  .plyr__caption > span {
    font-family: "Roboto";
    font-size: 1.6rem;
    font-weight: bold;
    font-stretch: extra-expanded;
    text-shadow: 1px 1px 3px #000, 1px -1px 3px #000, -1px -1px 3px #000,
      -1px 1px 3px #000;
    -webkit-font-smoothing: antialiased;
  }
}

@media screen and (max-width: 600px) {
  .plyr__caption > span {
    font-family: "Roboto";
    font-size: 1.8rem;
    font-weight: bold;
    font-stretch: extra-expanded;
    text-shadow: 1px 1px 3px #000, 1px -1px 3px #000, -1px -1px 3px #000,
      -1px 1px 3px #000;
    -webkit-font-smoothing: antialiased;
  }
}
