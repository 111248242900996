@import url("https://fonts.googleapis.com/css2?family=Anton&family=Sen&family=Roboto:wght@700&display=swap");
@font-face {
  font-family: "Font";
  src: local(Font), url(./assets/fonts/CircularStdMedium.woff2) format("woff2");
}

body {
  margin: 0;
  padding: 0;
  background-color: #181818;
}

* {
  font-family: "Font", sans-serif;
}

::-webkit-scrollbar {
  width: 0.25rem;
}

::-webkit-scrollbar-thumb {
  background-color: #ef4444;
  border-radius: 8px;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  color: red;
  background-color: #ff000000;
  -webkit-box-shadow: none;
}

::-moz-selection {
  color: #f87171;
  background: #e7e5e433;
}

::selection {
  color: #f87171;
  background: #e7e5e433;
}
