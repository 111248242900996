@import url(https://fonts.googleapis.com/css2?family=Anton&family=Sen&family=Roboto:wght@700&display=swap);
:root {
  --plyr-color-main: #ef4444;
  --plyr-control-icon-size: 20px;
  --plyr-badge-background: #ef4444;
  --plyr-badge-border-radius: 4px;
  --plyr-control-radius: 4px;
  --plyr-line-height: 2.2rem;
  --plyr-font-size-badge: 10px;
  --plyr-captions-background: #0000;
}

.plyr__caption {
  line-height: 100%;
}

.plyr__caption > span {
  font-family: "Roboto";
  font-size: 2.4rem;
  font-weight: bold;
  font-stretch: extra-expanded;
  text-shadow: 1px 1px 3px #000, 1px -1px 3px #000, -1px -1px 3px #000,
    -1px 1px 3px #000;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 1200px) {
  .plyr__caption > span {
    font-family: "Roboto";
    font-size: 1.8rem;
    font-weight: bold;
    font-stretch: extra-expanded;
    text-shadow: 1px 1px 3px #000, 1px -1px 3px #000, -1px 1px 3px #000,
      -1px 1px 3px #000;
    -webkit-font-smoothing: antialiased;
  }
}

@media screen and (max-width: 900px) {
  .plyr__caption > span {
    font-family: "Roboto";
    font-size: 1.6rem;
    font-weight: bold;
    font-stretch: extra-expanded;
    text-shadow: 1px 1px 3px #000, 1px -1px 3px #000, -1px -1px 3px #000,
      -1px 1px 3px #000;
    -webkit-font-smoothing: antialiased;
  }
}

@media screen and (max-width: 600px) {
  .plyr__caption > span {
    font-family: "Roboto";
    font-size: 1.8rem;
    font-weight: bold;
    font-stretch: extra-expanded;
    text-shadow: 1px 1px 3px #000, 1px -1px 3px #000, -1px -1px 3px #000,
      -1px 1px 3px #000;
    -webkit-font-smoothing: antialiased;
  }
}

@font-face {
  font-family: "Font";
  src: local(Font), url(/static/media/CircularStdMedium.e154e9f6.woff2) format("woff2");
}

body {
  margin: 0;
  padding: 0;
  background-color: #181818;
}

* {
  font-family: "Font", sans-serif;
}

::-webkit-scrollbar {
  width: 0.25rem;
}

::-webkit-scrollbar-thumb {
  background-color: #ef4444;
  border-radius: 8px;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  color: red;
  background-color: #ff000000;
  -webkit-box-shadow: none;
}

::selection {
  color: #f87171;
  background: #e7e5e433;
}

